<ng-container *ngIf="items()?.length">
	<ng-container *ngFor="let item of items()">
		<ng-container *ngIf="isItemHeadline(item); else noHeadline">
			<div class="navHeadline">
				<span>{{ item.name || (item.nameKey | msgKey) }}</span>
			</div>
			<bas-side-nav-hotlink
					*ngIf="item?.children?.length"
					[items]="item.children"
					[topLevel]="true"
			></bas-side-nav-hotlink>
		</ng-container>
		<ng-template #noHeadline>
			<bas-side-nav-item
					[item]="item"
					[class.hasChildren]="item.children?.length"
			></bas-side-nav-item>

			<bas-side-nav-hotlink
					*ngIf="showChildren(item)()"
					[items]="item.children"
			></bas-side-nav-hotlink>
		</ng-template>
	</ng-container>
</ng-container>
